import styles from "./sales.module.scss";
import "./swiper-sales.scss";
import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import Image from "next/image";
import Link from "next/link";
interface ISale {
  name: string;
  picture: string;
  code: string;
}
interface ISales {
  sales: Array<ISale>;
}
export const Sales: FC<ISales> = ({
  sales
}) => {
  const breakpoints = {
    1559: {
      spaceBetween: 36,
      slidesPerView: 3
    },
    1144: {
      spaceBetween: 32,
      slidesPerView: 2.5,
      allowTouchMove: true
    },
    820: {
      spaceBetween: 24,
      slidesPerView: 2,
      allowTouchMove: true
    },
    500: {
      spaceBetween: 24,
      slidesPerView: 1.5,
      allowTouchMove: true
    },
    320: {
      spaceBetween: 12,
      slidesPerView: 1,
      allowTouchMove: true
    }
  };
  return <Swiper className={"swiper-sales"} spaceBetween={16} allowTouchMove={false} slidesPerView={3} modules={[Navigation, Pagination]} navigation loop={false} breakpoints={breakpoints} pagination={{
    clickable: true
  }} data-sentry-element="Swiper" data-sentry-component="Sales" data-sentry-source-file="sales.tsx">
      {sales.map((item: ISale, index: number) => item.picture !== null && <SwiperSlide className={styles.item} key={`${index + "swiperSlidekey" + "Sales"}`}>
              <Link prefetch={false} href={`/sales/${item.code}`}>
                <Image className={styles.img} src={`https://ohotaktiv.ru${item.picture}`} alt={item.name} width={1100} height={620} />
              </Link>
            </SwiperSlide>)}
    </Swiper>;
};